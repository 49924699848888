import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OsItens } from 'src/app/models/osItens';
import { Parametros } from 'src/app/models/parametros';
import { ApiServiceService } from 'src/app/services/api-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ordem-servico',
  templateUrl: './ordem-servico.component.html',
  styleUrls: ['./ordem-servico.component.css']
})
export class OrdemServicoComponent implements OnInit {
  osItens = new OsItens();
  parametros = new Parametros();

  public arrayItensOs = [];
  public arrayClientes = [];
  public total;
  constructor(private provider: ApiServiceService, private router: Router) { }

  ngOnInit(): void {
    this.getCliente();
    this.getOrdemServicoItens();
    this.parametros.osAbertura = false;
    if(!sessionStorage.getItem("user")){
      this.router.navigate([('/login')]);
    }
  };




  getCliente() {
    //this.parametros.loading = true;
    this.arrayClientes = [];
    return new Promise(resolve => {
      const dados = {
        requisicao: 'getCliente',
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      console.log(dados);
      this.provider.api(dados, 'apiCliente.php')
        .then((data) => {
          if (data['success']) {
            //this.parametros.loading = false;
            //console.log(data);
            for (let dado of data['result']) {
              this.arrayClientes.push(dado);
            }
          } else {
            //this.parametros.loading = false;
            console.log(data['erro']);
          }
        })
        .catch()
    })
  }

  decideAbreOs(){
    if(this.parametros.osAbertura == true){
      if(this.osItens.cliente == null || this.osItens.paciente == null){
        Swal.fire({
          title: 'ATENÇÃO',
          text: 'É necessário informar o Cliente e o Paciente para prosseguir',
          icon: 'info',
          confirmButtonText: 'OK',
        })
      }else{
        this.putItemOs();
      }
    }else{
      this.putItemOs();
    }
  }

  putItemOs() {
    this.parametros.osAbertura = false; // para corrigir bug na tela onde aparecia o quadrante de paciente quando nao devia.
    return new Promise(resolve => {
      const dados = {
        requisicao: 'putItemOs',
        item_descricao: this.osItens.descricao,
        item_valor: this.osItens.preco,
        cod_cliente: this.osItens.cliente,
        paciente: this.osItens.paciente
        //user: sessionStorage.getItem("user"),
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      console.log(dados);
      this.provider.api(dados, 'apiOrdemServico.php')
        .then((data) => {
          if (data['success']) {
            
            Swal.fire({
              title: 'ITEM',
              text: 'Inserido com sucesso',
              icon: 'success',
              confirmButtonText: 'Ok!',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });

          } else {
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'Ok!',
            })
            console.log(data['erro']);
          }
        })
        .catch()
    })
  }

  getOrdemServicoItens() {
    this.parametros.osAbertura = false;
    this.parametros.loading = true;
    this.arrayItensOs = [];
    return new Promise(resolve => {
      const dados = {
        requisicao: 'getItensOs',
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      console.log(dados);
      this.provider.api(dados, 'apiOrdemServico.php')
        .then((data) => {
          if (data['success']) {
            this.parametros.loading = false;
            this.parametros.osAbertura = false;
            //console.log(data);
            for (let dado of data['result']) {
               
              this.total = dado.soma;
              this.arrayItensOs.push(dado);
            }
          } else {
            this.parametros.loading = false;
            this.parametros.osAbertura = true;
            Swal.fire({
              title: 'INFO',
              text: data['erro'],
              icon: 'info',
              confirmButtonText: 'OK'
            });
          }
        })
        .catch()
    })
  }

  putOsFinish() {
    return new Promise(resolve => {
      const dados = {
        requisicao: 'putOsFinish',
        //user: sessionStorage.getItem("user"),
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiOrdemServico.php')
        .then((data) => {
          if (data['success']) {
            Swal.fire({
              title: 'OS',
              text: 'Finalizada com Sucesso!',
              icon: 'success',
              confirmButtonText: 'Ok!',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });

          } else {
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
            console.log(data);
          }
        })
        .catch()
    })
  }

  decideCancela(){
    Swal.fire({
      title: 'ATENÇÃO',
      text: 'Deseja mesmo cancelar a OS em aberto?',
      icon: 'warning',
      confirmButtonText: 'SIM',
      showDenyButton: true,
      denyButtonText: 'NÃO'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('clicou no confirma');
      }else{
        console.log('clicou no cancela');
      }
    });
  }

  putOsCancela() {
    return new Promise(resolve => {
      const dados = {
        requisicao: 'putOsCancela',
        //user: sessionStorage.getItem("user"),
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiOrdemServico.php')
        .then((data) => {
          if (data['success']) {
            Swal.fire({
              title: 'OS',
              text: 'Cancelada com Sucesso!',
              icon: 'success',
              confirmButtonText: 'Ok!',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });

          } else {
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'Ok!',
            })
            console.log(data['erro']);
          }
        })
        .catch()
    })
  }

}

