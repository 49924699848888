import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teste',
  templateUrl: './teste.component.html',
  styleUrls: ['./teste.component.css']
})
export class TesteComponent implements OnInit {

  constructor() { }

  users = [
    { name: 'João', email: 'joao23@example.com' },
    { name: 'Maria', email: 'maria@example.com' },
    { name: 'Ana', email: 'ana@example.com' },
    { name: 'Pedro', email: 'pedro@example.com' }
  ];

  filterText = '';
  filteredUsers = [...this.users];

  ngOnInit() {
    this.applyFilter();
  }

  applyFilter() {
    this.filteredUsers = this.users.filter(user => 
      user.name.toLowerCase().includes(this.filterText.toLowerCase()) ||
      user.email.toLowerCase().includes(this.filterText.toLowerCase())
    );
  }

}
