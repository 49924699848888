<div class="ui form attached fluid segment" *ngIf="parametros.osAbertura">
    <div class="two fields">
        <div class="field">
            <label for="cliente">Cliente</label>
            <select class="ui fluid dropdown" name="cliente" [(ngModel)]="this.osItens.cliente">
                <option *ngFor="let c of arrayClientes" class="item" [value]="c.cod_cliente">{{c.nome}}</option>
               
              </select>
        </div>
        <div class="field">
            <label>Paciente</label>
            <input type="text" name="paciente" placeholder="Paciente" [(ngModel)]="this.osItens.paciente">
        </div>
    </div>
</div>
<br>
<form class="ui form attached fluid segment">
    
    <div class="two fields">
        <div class="field">
            <label>Descrição</label>
            <input type="text" name="descricao" placeholder="Serviço realizado" [(ngModel)]="this.osItens.descricao">
          </div>
          <div class="field">
            <label>Preço</label>
            <input type="number" name="preco"  [(ngModel)]="this.osItens.preco">
          </div>
    </div>    
    <div class="ui blue huge submit fluid button" (click)="decideAbreOs()">Adicionar</div>
  </form>
  <br>
  <div class="loadTamanho" *ngIf="parametros.loading">
    <div class="ui active centered inline loader"></div>
</div>


  <div class="ui form attached fluid segment" *ngIf="!parametros.loading">
    <table class="ui celled table">
      <thead>
        <tr><th>Descrição</th>
        <th>Preco</th>
      </tr></thead>
      <tbody>
        <tr *ngFor="let os of arrayItensOs">
          <td data-label="descricao">{{os.item_descricao}}</td>
          <td data-label="grupo">R$: {{os.item_valor}}</td>
        </tr>
        <tr><th>TOTAL</th><td class="warning">R$: {{this.total}}</td></tr>
      </tbody>
    </table>
    <div class="ui green huge  fluid button" (click)="putOsFinish()">Finalizar OS</div>
    <br>
    <div class="ui red huge  fluid button" (click)="decideCancela()">Cancelar OS</div>
  </div>

  



