import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsuarioHistoricoComponent } from './componentes/usuario-historico/usuario-historico.component';
import { CadastroLoginComponent } from './components/cadastro-login/cadastro-login.component';
import { ContasReceberComponent } from './components/contas-receber/contas-receber.component';
import { ListaUsuariosComponent } from './components/lista-usuarios/lista-usuarios.component';
import { LoginComponent } from './components/login/login.component';
import { OrdemServicoComponent } from './components/ordem-servico/ordem-servico.component';
import { ProdutoComponent } from './components/produto/produto.component';
import { UsuarioProdutoComponent } from './components/usuario-produto/usuario-produto.component';
import { AlteraSenhaComponent } from './components/altera-senha/altera-senha.component';
import { TesteComponent } from './components/teste/teste.component';

const routes: Routes = [
  {path:'', pathMatch: 'full', redirectTo: 'login'},
  {path: 'produto', component: ProdutoComponent},
  {path: 'login', component: LoginComponent},
  {path: 'cadastro-login', component: CadastroLoginComponent},
  {path: 'os', component: OrdemServicoComponent},
  {path: 'receber', component: ContasReceberComponent},
  {path: 'usuarios', component: ListaUsuariosComponent},
  {path: 'usuario-produto', component: UsuarioProdutoComponent},
  {path: 'usuario-historico', component: UsuarioHistoricoComponent},
  {path: 'alterar-senha', component: AlteraSenhaComponent},
  {path: 'teste', component: TesteComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
