import { Component, OnInit } from '@angular/core';
import { Cliente } from 'src/app/models/cliente';
import { Parametros } from 'src/app/models/parametros';
import { ApiServiceService } from 'src/app/services/api-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-usuarios',
  templateUrl: './lista-usuarios.component.html',
  styleUrls: ['./lista-usuarios.component.css']
})
export class ListaUsuariosComponent implements OnInit {
  parametros = new Parametros();
  cliente = new Cliente();
  public clienteLista=[];
  constructor(private provider: ApiServiceService) { }

  ngOnInit(): void {
    this.getListaUsuarios();
  }
  ativaBloquea(num){
    if(num == 1){
      return true;
    }else{
      return false;
    }
  }


  getListaUsuarios() {
    this.parametros.loading = true;
    this.clienteLista = [];
    return new Promise(resolve => {
      const dados = {
        requisicao: 'getListaUsuarios',
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiCliente.php')
        .then((data) => {
          if (data['success']) {
            this.parametros.loading = false;
            //console.log(data);
            for(let dado of data['result']){
              this.clienteLista.push(dado);
            }
          } else { 
            this.parametros.loading = false;
            Swal.fire({
              title: 'INFO',
              text: data['erro'],
              icon: 'info',
              confirmButtonText: 'OK'
            });
          }
        })
        .catch()
    })
  }

  putLiberaUsuario(cod){
    this.parametros.loading = true;
    return new Promise(resolve => {
      const dados = {
        requisicao: 'putLiberaUsuario',
        cod_cliente: cod
        //user: sessionStorage.getItem("user"),
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiCliente.php')
        .then((data) => {
          if (data['success']) {
            //this.parametros.loading = false;
            Swal.fire({
              title: 'USUÁRIO',
              text: 'LIBERADO COM SUCESSO!',
              icon: 'success',
              confirmButtonText: 'Ok!',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
           
          } else {
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
            //this.parametros.loading = false;
            //console.log(data['erro']);
          }
        })
        .catch()
    })
  }

  putBloqueiaUsuario(cod){
    //this.parametros.loading = true;
    return new Promise(resolve => {
      const dados = {
        requisicao: 'putBloqueiaUsuario',
        cod_cliente: cod
        //user: sessionStorage.getItem("user"),
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiCliente.php')
        .then((data) => {
          if (data['success']) {
            //this.parametros.loading = false;
            Swal.fire({
              title: 'USUÁRIO',
              text: 'BLOQUEADO COM SUCESSO!',
              icon: 'success',
              confirmButtonText: 'Ok!',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
           
          } else {
            //this.parametros.loading = false;
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }
        })
        .catch()
    })
  }

}
