import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cliente } from 'src/app/models/cliente';
import { Parametros } from 'src/app/models/parametros';
import { ApiServiceService } from 'src/app/services/api-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-altera-senha',
  templateUrl: './altera-senha.component.html',
  styleUrls: ['./altera-senha.component.css']
})
export class AlteraSenhaComponent implements OnInit {
  parametros = new Parametros();
  cliente =  new Cliente();

  constructor(private provider:ApiServiceService, private router:Router) { }

  ngOnInit(): void {
    this.parametros.loading = false;
  }

  validaSenha(){
    if(this.cliente.senha == this.cliente.repete_senha){
      this.putAlteraSenha();
    }else{
      Swal.fire({
        title: 'Atenção',
        text: 'As senhas informadas não combinam!',
        icon: 'warning',
        confirmButtonText: 'OK',
      })
    }
  }



  putAlteraSenha(){
    this.parametros.loading = true;
    return new Promise(resolve => {
      const dados = {
        requisicao: 'putAlteraSenha',
        chave: this.cliente.chave,
        cro: this.cliente.cro,
        nova_senha: this.cliente.senha,
        //user: sessionStorage.getItem("user"),
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      console.log(dados);
      this.provider.api(dados, 'apiCliente.php')
        .then((data) => {
          if (data['success']) {
            this.parametros.loading = false;
            Swal.fire({
              title: 'SENHA',
              text: 'Alterada com sucesso!',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigateByUrl('/login');
              }
            });
            
          } else {
            this.parametros.loading = false;
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }
        })
        .catch()
    })
  }

}
