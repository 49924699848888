<div class="loadTamanho" *ngIf="parametros.loading">
  <div class="ui active centered inline loader"></div>
</div>

<h3 class="alinhamento"><b>Lista de Clientes</b></h3>
<div class="filtro">
  <label for="pesquisa">Pesquisar</label>
  <input id="pesquisa" type="text" class="form-control" [(ngModel)]="this.filtroCliente" (input)="applyFilterClient()">
</div>
<table class="ui celled black large  selectable table">
  <thead>
    <tr>
      <th class="ten wide">Dr.</th>
      <th>Ações</th>

    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let co of listaClienteFiltrada">
      <td data-label="descricao" (click)="filtraLista(co.cod_cliente, co.nome, co.saldo_devedor)">{{co.nome}}</td>
      <td>
        <button class="ui inverted red button" data-toggle="modal" data-target="#modalServico"
          (click)="preencheClienteLancamentoServico(co.cod_cliente)">Serviço</button>

        <button class="ui inverted green button" data-toggle="modal" data-target="#modalLancamento"
          (click)="preencheClienteLancamentoServico(co.cod_cliente)">Pagamento</button>

      </td>
    </tr>
  </tbody>
</table>

<br>

<div *ngIf="this.habilitaLista">
  <h3 class="alinhamento"><b>Histórico {{this.cliente.nome}}</b></h3>

<div>
  <div class="filtro">
    <label for="filtro">Pesquisar</label>
    <input id="filtro" type="text" class="form-control inline" [(ngModel)]="this.filtroServicos" (input)="applyFilter()">
  </div>
</div>
<table class="ui celled blue table">
  <thead>
    <tr>

      <th>
        <h4><b>Paciente</b></h4>
      </th>
      <th>
        <h4><b>Trabalho Discriminado</b></h4>
      </th>
      <th>
        <h4><b>Data</b></h4>
      </th>
      <th>
        <h4><b>Valor</b></h4>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let iol of listaFiltrada" class="{{iol.tipo}}">
      <td data-label="preco">{{iol.paciente}}</td>
      <td data-label="item_descricao">{{iol.item_descricao}}</td>
      <td data-label="data">{{iol.data}}</td>
      <td data-label="item_valor">R${{iol.item_valor}}</td>
    </tr>
    <tr>
      <th></th>
      <th></th>
      <th>
        <h4><b>TOTAL</b></h4>
      </th>
      <td class="{{this.parametros.corSaldoTotal}}">R$: {{this.cliente.saldo_devedor}}</td>
    </tr>
  </tbody>
</table>
</div>



<!--MODAL INSERIR PAGAMENTO -->
<div class="modal fade" id="modalLancamento" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel"><b>LANÇAR PAGAMENTO</b></h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="ui form">

          <div class="field">
            <label>
              <h3><b>Descricao</b></h3>
            </label>
            <input type="text" name="descricao" [(ngModel)]="this.contaCorrente.item_descricao">
          </div>

          <div class="field">
            <label>
              <h3><b>Valor</b></h3>
            </label>
            <input type="number" name="preco" [(ngModel)]="this.contaCorrente.item_valor">
          </div>

        </form>
      </div>
      <div class="modal-footer">
        <button class="ui primary button" (click)="postPagamento()">
          <div *ngIf="!this.parametros.loading">Salvar </div>
          <div *ngIf="this.parametros.loading" class="ui active inline loader"></div>
        </button>
        <button class="ui button" data-dismiss="modal">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>


<!--MODAL INSERIR SERVICO -->
<div class="modal fade" id="modalServico" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel"><b>REGISTRAR SERVIÇO</b></h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="ui form">

          <div class="field">
            <label>
              <h3><b>Descricao</b></h3>
            </label>
            <input type="text" name="descricao" [(ngModel)]="this.contaCorrente.item_descricao">
          </div>

          <div class="field">
            <label>
              <h3><b>Valor</b></h3>
            </label>
            <input type="number" name="preco" [(ngModel)]="this.contaCorrente.item_valor">
          </div>

          <div class="field">
            <label>
              <h3><b>Paciente</b></h3>
            </label>
            <input type="text" name="preco" [(ngModel)]="this.contaCorrente.paciente">
          </div>

        </form>
      </div>
      <div class="modal-footer">
        <button class="ui primary button" (click)="postContaCorrente()">
          <div *ngIf="!this.parametros.loading">Salvar </div>
          <div *ngIf="this.parametros.loading" class="ui active inline loader"></div>
        </button>
        <button class="ui button" data-dismiss="modal">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</div>