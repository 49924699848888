<div class="loadTamanho" *ngIf="parametros.loading">
  <div class="ui active centered inline loader"></div>
</div>

<table class="ui celled black table">
    <thead>
        <tr>
            <th>Nome</th>
            <th>Status</th>
            <th>Ações</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let c of clienteLista">
            <td data-label="descricao">{{c.nome}}</td>
            <td data-label="grupo">{{c.status}}</td>
            <td data-label="acoes">
                <button class="ui inverted green button" *ngIf="!ativaBloquea(c.ativo)"
                    (click)="putLiberaUsuario(c.cod_cliente)">Ativar Usuário</button>
                <button class="ui inverted red button" *ngIf="ativaBloquea(c.ativo)"
                    (click)="putBloqueiaUsuario(c.cod_cliente)">Bloquear Usuário</button>

               
            </td>
        </tr>
    </tbody>
</table>