<div class="loadTamanho" *ngIf="parametros.loading">
    <div class="ui active centered inline loader"></div>
  </div>
<br>
<br>
<div>
    <div class="ui middle aligned divided list">
        <div class="item {{list.tipo}}" *ngFor="let list of contaCorrenteLista">
            <div class="right floated content">
                <br>
                <br><br>
                <br>
                <div class="line">R$: {{list.item_valor}}</div>
            </div>
            <div class="content">
                <h5><b class="color">Paciente: </b>{{list.paciente}}</h5>
                <h5><b class="color">Trabalho: </b>{{list.item_descricao}}</h5>
                <h5><b class="color">Data: </b>{{list.data}}</h5>

            </div>
        </div>
        <br>
    <div class="ui blue huge red fluid button">Saldo Devedor: R${{this.cliente.saldo_devedor}}</div>
        
        
    </div>

</div>