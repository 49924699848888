import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';
import { Component, OnInit } from '@angular/core';
import { Cliente } from 'src/app/models/cliente';
import { ContaCorrente } from 'src/app/models/contaCorrente';
import { Lancamento } from 'src/app/models/lancamento';
import { Parametros } from 'src/app/models/parametros';
import { ApiServiceService } from 'src/app/services/api-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contas-receber',
  templateUrl: './contas-receber.component.html',
  styleUrls: ['./contas-receber.component.css']
})
export class ContasReceberComponent implements OnInit {
  parametros = new Parametros();
  lancamentos = new Lancamento();
  contaCorrente = new ContaCorrente();
  cliente = new Cliente();




  public clienteOsLista = [];
  public itensOsLista = [];
  public teste;
  public arrayOsLista = [];
  public filtroServicos = '';
  public filtroCliente = '';
  public listaClienteFiltrada = [];
  public listaFiltrada = [];
  public habilitaLista = false;

  constructor(private provider: ApiServiceService) { }

  ngOnInit(): void {
    this.getContaCorrente();
    this.applyFilter();
  }

  preencheClienteLancamentoServico(cod_cliente) {
    this.contaCorrente.cod_cliente = cod_cliente;
  }

  preencheLancamento(nome, saldo_devedor, cod_cliente) {
    this.lancamentos.nome = nome;
    this.lancamentos.saldo = saldo_devedor;
    this.lancamentos.cod_cliente = cod_cliente;
  }

  getContaCorrente() {
    this.parametros.loading = true;
    this.clienteOsLista = [];
    this.itensOsLista = [];
    return new Promise(resolve => {
      const dados = {
        requisicao: 'getContaCorrente',
        cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiContaCorrente.php')
        .then((data) => {
          if (data['success']) {
            this.parametros.loading = false;
            //console.log(data);
            for (let dado of data['result']) {
              this.clienteOsLista.push(dado);
            }
            for (let dado2 of data['resultItens']) {
              this.itensOsLista.push(dado2);
            }
            this.listaClienteFiltrada = [...this.clienteOsLista];
            console.log(this.listaClienteFiltrada);
          } else {
            this.parametros.loading = false;
            Swal.fire({
              title: 'Info!',
              text: data['erro'],
              icon: 'info',
              confirmButtonText: 'OK',
            })
          }
        })
        .catch()
    })
  }

  putContasReceber() {
    return new Promise(resolve => {
      const dados = {
        requisicao: 'putContasReceber',
        item_descricao: this.lancamentos.item_descricao,
        item_valor: this.lancamentos.item_valor,
        cod_cliente: this.lancamentos.cod_cliente
        //user: sessionStorage.getItem("user"),
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiContasPagarReceber.php')
        .then((data) => {
          if (data['success']) {
            Swal.fire({
              title: 'AMORTIZAÇÃO',
              text: 'LANÇADA COM SUCESSO!',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });

          } else {
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
            //console.log(data['erro']);
          }
        })
        .catch()
    })
  }


  postContaCorrente() {
    this.parametros.loading = true;
    return new Promise(resolve => {
      const dados = {
        requisicao: 'postContaCorrente',
        item_descricao: this.contaCorrente.item_descricao,
        item_valor: this.contaCorrente.item_valor,
        cod_cliente: this.contaCorrente.cod_cliente,
        paciente: this.contaCorrente.paciente,
        cod_empresa: sessionStorage.getItem("cod_empresa")
        //user: sessionStorage.getItem("user"),

      };
      //console.log(dados);
      this.provider.api(dados, 'apiContaCorrente.php')
        .then((data) => {
          if (data['success']) {
            this.parametros.loading = false;
            Swal.fire({
              title: 'Serviço',
              text: 'Inserido com Sucesso!',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });

          } else {
            this.parametros.loading = false;
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
            //console.log(data['erro']);
          }
        })
        .catch()
    })
  }

  cssTotal() {
    if (this.cliente.saldo_devedor > 0) {
      this.parametros.corSaldoTotal = 'positivo';
    } else if (this.cliente.saldo_devedor < 0) {
      this.parametros.corSaldoTotal = 'negativo';
    } else {
      this.parametros.corSaldoTotal = 'neutro';
    }
  }

  postPagamento() {
    this.parametros.loading = true;
    return new Promise(resolve => {
      const dados = {
        requisicao: 'postPagamento',
        item_descricao: this.contaCorrente.item_descricao,
        item_valor: this.contaCorrente.item_valor,
        cod_cliente: this.contaCorrente.cod_cliente,
        cod_empresa: sessionStorage.getItem("cod_empresa")
        //user: sessionStorage.getItem("user"),
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiContaCorrente.php')
        .then((data) => {
          if (data['success']) {
            this.parametros.loading = false;
            Swal.fire({
              title: 'Pagamento',
              text: 'Inserido com Sucesso!',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });

          } else {
            this.parametros.loading = false;
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
            //console.log(data['erro']);
          }
        })
        .catch()
    })
  }


  filtraLista(cod_cliente, nome, saldo) {
    this.cliente.nome = "de " + nome;
    this.cliente.saldo_devedor = saldo;
    this.cssTotal()
    this.arrayOsLista = this.itensOsLista.filter((lista, index, array) => lista.cod_cliente == cod_cliente);
    this.habilitaLista = true;
    this.listaFiltrada = [...this.arrayOsLista];
    //console.log(cod_cliente);
  }

  //Criado dia 26/06/2024 para filtrar na lista de cliente
  applyFilterClient() {
    this.listaClienteFiltrada = this.clienteOsLista.filter(item =>
      (item.cod_cliente && item.cod_cliente.toLowerCase().includes(this.filtroCliente.toLowerCase())) ||
      (item.nome && item.nome.toLowerCase().includes(this.filtroCliente.toLowerCase())) ||
      (item.saldo_devedor && item.saldo_devedor.toLowerCase().includes(this.filtroCliente.toLowerCase()))
    );
  }
  //Criado dia 26/06/2024 para filtrar no histórico do cliente
  applyFilter() {
    this.listaFiltrada = this.arrayOsLista.filter(item =>
      (item.paciente && item.paciente.includes(this.filtroServicos)) ||
      (item.item_descricao && item.item_descricao.toLowerCase().includes(this.filtroServicos.toLowerCase())) ||
      (item.data && item.data.toLowerCase().includes(this.filtroServicos.toLowerCase())) ||
      (item.item_valor && item.item_valor.toLowerCase().includes(this.filtroServicos.toLowerCase()))
    );
  }
}
