import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'restaureSorrisos';
  logado(){
    if(sessionStorage.getItem("user")){
      //console.log("Verdadeiro");
      return true;
    }else{
      //console.log("Falso");
      return false;
    }
  }
}
