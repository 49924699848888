<div class="loadTamanho" *ngIf="parametros.loading">
    <div class="ui active centered inline loader"></div>
  </div>
<br>

<div>
    <div class="ui celled list">
        <div class="item">
            <h3><b>Metálo Cerâmica</b></h3>
            <div class="list" *ngFor="let tp of this.arrayMetalo">
                <span>{{tp.descricao}}</span>
                <p><b class="money">R${{tp.preco}}</b></p>
            </div>
            <br>
        </div>
        <br>
        <div class="item">
            <h3><b>Metal Free</b></h3>
            <div class="list" *ngFor="let tp of this.arrayMetal">
                <span>{{tp.descricao}}</span>
                <p><b class="money">R${{tp.preco}}</b></p>
            </div>
            <br>
        </div>
        <br>
        <div class="item">
            <h3><b>Resinas</b></h3>
            <div class="list" *ngFor="let tp of this.arrayResina">
                <span>{{tp.descricao}}</span>
                <p><b class="money">R${{tp.preco}}</b></p>
            </div>
            <br>
        </div>
        <br>
        <div class="item">
            <h3><b>Metais</b></h3>
            <div class="list" *ngFor="let tp of this.arrayMetais">
                <span>{{tp.descricao}}</span>
                <p><b class="money">R${{tp.preco}}</b></p>
            </div>
            <br>
        </div>
        <br>
        <div class="item">
            <h3><b>Bio-plas</b></h3>
            <div class="list" *ngFor="let tp of this.arrayBioplas">
                <span>{{tp.descricao}}</span>
                <p><b class="money">R${{tp.preco}}</b></p>
            </div>
            <br>
        </div>
        <br>
        <div class="item">
            <h3><b>Outros</b></h3>
            <div class="list" *ngFor="let tp of this.arrayOutros">
                <span>{{tp.descricao}}</span>
                <p><b class="money">R${{tp.preco}}</b></p>
            </div>
            <br>
        </div>
    </div>
    <br><br>
    <div class="centro">
        
        <span>Para maiores informações entrar em contato pelo</span><br>
        <a href="https://wa.me/55042984049670?text=Olá%20Rodrigo">Whastapp<i class="whatsapp icon"></i></a><br>

        Ou pelo e-mail: rodrigo.h2000@hotmail.com <br>
        <div class="tpd">TPD 1723</div><br>

        <img class="ui centered small  image" src="\assets\images\RS.jpg">
    </div>
</div>


<!--<div class="item" *ngFor="let p of this.listaTipoProduto">
    <h3><b>{{p.descricao}}</b></h3>
    <div class="list" *ngFor="let tp of this.listaProduto" (onChange)="ajustaArray(tp.cod_tipo_produto)">
            <h5 *ngIf="p.descricao == tp.tipo_produto">{{tp.descricao}}</h5>
            <p *ngIf="p.descricao == tp.tipo_produto"><b class="money">R${{tp.preco}}</b></p>
    </div>
    <br>
</div>   -->