import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  server = 'https://szulhacorps.com.br/ServiceOrder/back-end/';
  constructor(private http: HttpClient) { }

  api(dados:any, api:string){
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type':'application/json'})
    };
    let url = this.server + api;
    return this.http.post(url, JSON.stringify(dados), httpOptions).toPromise();
  }
}
