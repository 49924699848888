


<div class="ui attached message">
    <div class="header">
      Bem vindo a Restaure Sorrisos Proteses Digitais!
    </div>
    <p>Para ter acesso ao aplicativo, precisaremos de algumas informações para viabilizar o seu acesso</p>
  </div>
  <form class="ui form attached fluid segment">
    <div class="two fields">
        <div class="field">
            <label>Nome Completo</label>
            <input type="text" name="nome-completo" placeholder="Ex: Joãozinho da Silva" [(ngModel)]="this.cliente.nome">
          </div>
          <div class="field">
            <label>CRO</label>
            <input type="text" name="cro" placeholder="nº do CRO" [(ngModel)]="this.cliente.cro">
          </div>
    </div>
    
    <div class="field">
      <label>Senha</label>
      <input type="password" name="senha" [(ngModel)]="this.cliente.senha">
    </div>
    
    <div class="ui blue huge submit fluid button" (click)="postCliente()">
      <div *ngIf="!this.parametros.loading">Cadastrar </div>
              <div *ngIf="this.parametros.loading" class="ui active inline loader"></div>
    </div>
  </form>
  