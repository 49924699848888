import { Component, OnInit } from '@angular/core';
import { Cliente } from 'src/app/models/cliente';
import { Parametros } from 'src/app/models/parametros';
import { ApiServiceService } from 'src/app/services/api-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuario-historico',
  templateUrl: './usuario-historico.component.html',
  styleUrls: ['./usuario-historico.component.css']
})
export class UsuarioHistoricoComponent implements OnInit {
   public contaCorrenteLista = [];
   parametros = new Parametros();
   cliente = new Cliente();
  constructor(private provider: ApiServiceService) { }

  ngOnInit(): void {
    this.getContaCorrente();
  }


  getContaCorrente() {
    this.parametros.loading = true;
    this.contaCorrenteLista = [];
    return new Promise(resolve => {
      const dados = {
        requisicao: 'getContaCorrenteUsuario',
        //cod_empresa: sessionStorage.getItem("cod_empresa")
        cod_cliente: sessionStorage.getItem("cod_cliente")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiContaCorrente.php')
        .then((data) => {
          if (data['success']) {
            this.parametros.loading = false;
            //console.log(data);
            for (let dado of data['result']) {
              this.cliente.saldo_devedor = dado.saldo;
              this.contaCorrenteLista.push(dado);
            }
            //console.log(this.cliente.saldo_devedor);
          } else {
            console.log(data);
            this.parametros.loading = false;
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }
        })
        .catch()
    })
  }

}
