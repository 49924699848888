import { Component, OnInit } from '@angular/core';
import { Parametros } from 'src/app/models/parametros';
import { ApiServiceService } from 'src/app/services/api-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuario-produto',
  templateUrl: './usuario-produto.component.html',
  styleUrls: ['./usuario-produto.component.css']
})
export class UsuarioProdutoComponent implements OnInit {
  public listaTipoProduto = [];
  public listaProduto = [];
  public arrayProduto = [];
  public arrayMetalo = [];
  public arrayMetal = [];
  public arrayResina = [];
  public arrayMetais = [];
  public arrayBioplas = [];
  public arrayOutros = [];
  parametros = new Parametros();
 

  constructor(private provider: ApiServiceService) { }

  ngOnInit(): void {
    this.getProduto();
  }

  getProduto() {
    this.parametros.loading = true;
    this.listaProduto = [];
    return new Promise(resolve => {
      const dados = {
        requisicao: 'getProduto',
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiProduto.php')
        .then((data) => {
          if (data['success']) {
            this.parametros.loading = false;
            //console.log(data);
            for(let dado of data['result']){
              this.listaProduto.push(dado);
              
            }
            for(let dado2 of data['result2']){
              this.listaTipoProduto.push(dado2);
            }
            this.ajustaArray();
          } else { 
            this.parametros.loading = false;
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }
        })
        .catch()
    })
  }

  ajustaArray(){
      this.listaProduto.forEach(element => {
        if(element.cod_tipo_produto == 4){
          this.arrayMetalo.push(element);
        }else if(element.cod_tipo_produto == 5){
          this.arrayMetal.push(element);
        }else if(element.cod_tipo_produto == 6){
          this.arrayResina.push(element);
        }else if(element.cod_tipo_produto == 7){
          this.arrayMetais.push(element);
        }else if(element.cod_tipo_produto == 8){
          this.arrayOutros.push(element);
        }else if(element.cod_tipo_produto == 9){
          this.arrayBioplas.push(element);
        }

        
        
      });
      //console.log(this.arrayMetalo);
      //console.log(this.arrayMetal);
      //console.log(this.arrayResina);
      //console.log(this.arrayMetais);
      //console.log(this.arrayOutros);
    
  }

}
