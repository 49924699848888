import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MenuComponent } from './components/menu/menu.component';
import { ProdutoComponent } from './components/produto/produto.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { CadastroLoginComponent } from './components/cadastro-login/cadastro-login.component';
import { OrdemServicoComponent } from './components/ordem-servico/ordem-servico.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ContasReceberComponent } from './components/contas-receber/contas-receber.component';
import { ListaUsuariosComponent } from './components/lista-usuarios/lista-usuarios.component';
import { UsuarioProdutoComponent } from './components/usuario-produto/usuario-produto.component';
import { UsuarioHistoricoComponent } from './componentes/usuario-historico/usuario-historico.component';
import { AlteraSenhaComponent } from './components/altera-senha/altera-senha.component';
import { TesteComponent } from './components/teste/teste.component';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ProdutoComponent,
    LoginComponent,
    CadastroLoginComponent,
    OrdemServicoComponent,
    ContasReceberComponent,
    ListaUsuariosComponent,
    UsuarioProdutoComponent,
    UsuarioHistoricoComponent,
    AlteraSenhaComponent,
    TesteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    SweetAlert2Module
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
