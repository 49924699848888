<div class="ui attached message">
    <div class="header">
      Redefinir senha!
    </div>
    <p>Para redefinir, basta informar o seu nº CRO, a palavra chave cadastrada e a nova senha</p>
  </div>
  <form class="ui form attached fluid segment">
    <div class="two fields">
        <div class="field">
            <label>CRO</label>
            <input type="text" name="cro" placeholder="nº do CRO" [(ngModel)]="this.cliente.cro">
          </div>
          <div class="field">
            <label>Palavra Chave</label>
            <input type="text" name="chave" [(ngModel)]="this.cliente.chave">
          </div>
          
    </div>
    
    <div class="two fields">
      <div class="field">
        <label>Nova senha</label>
        <input type="password" name="senha"  [(ngModel)]="this.cliente.senha">
      </div>
      <div class="field">
        <label>repita sua senha</label>
        <input type="password" name="cro" [(ngModel)]="this.cliente.repete_senha">
      </div>
    </div>
    
    <div class="ui blue huge submit fluid button" (click)="validaSenha()">
      Alterar Senha
    </div>
  </form>