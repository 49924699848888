export class Cliente {
    nome: string;
    cro: string;
    saldo_devedor: number;
    usuario: string;
    senha: string;
    repete_senha: string;
    cod: number;
    chave: string;

    constructor() {
        this.nome = null;
        this.cro = null;
        this.saldo_devedor = null;
        this.usuario = null;
        this.senha = null;
        this.repete_senha = null;
        this.cod = null;
        this.chave = null;
    }
}