<div class="loadTamanho" *ngIf="parametros.loading">
    <div class="ui active centered inline loader"></div>
</div>


<button class="ui basic button" (click)="limpaModal()" data-toggle="modal" data-target="#modalExemplo">
    <i class="icon plus"></i>
    Produto
</button>

<table class="ui celled black table">
    <thead>
      <tr><th>Descrição</th>
      <th>Grupo</th>
      <th>Preço</th>
      <th>Ações</th>
    </tr></thead>
    <tbody>
      <tr *ngFor="let p of produtoLista">
        <td data-label="descricao">{{p.descricao}}</td>
        <td data-label="grupo">{{p.tipo_produto}}</td>
        <td data-label="preco"><b class="preco">R${{p.preco}}</b></td>
        <td data-label="acoes">
            <div class="ui buttons">
                <button class="ui olive button" (click)="preencheModal(p.cod_produto,p.descricao,p.preco,p.cod_tipo_produto)" data-toggle="modal" data-target="#modalEditarProduto">editar</button>
                <div class="or" data-text="ou"></div>
                <button class="ui negative button active"(click)="decideDeleta(p.cod_produto,p.descricao)">Excluir</button>
              </div>
        </td>
      </tr>
    </tbody>
  </table>

<!--MODAL INSERIR PRODURTO -->
<div class="modal fade" id="modalExemplo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel"><b>Cadastrar Produto</b></h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="ui form">
                    <div class="field">
                        <label><h3>Tipo</h3></label>
                        <select class="ui fluid dropdown" [(ngModel)]="produto.tipoProduto"
                            [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let m of tipoProdutoLista" class="item" [value]="m.cod">{{m.descricao}}</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>
                            <h3><b>Descricao</b></h3>
                        </label>
                        <input type="text" name="descricao"  [(ngModel)]="produto.descricao">
                    </div>
                   
                    <div class="field">
                        <label>
                            <h3><b>Valor</b></h3>
                        </label>
                        <input type="number" name="preco" [(ngModel)]="produto.preco">
                    </div>
                    
                </form>
            </div>
            <div class="modal-footer">
                <button class="ui primary button" (click)="postProduto()">
                    Salvar
                </button>
                <button class="ui button" data-dismiss="modal">
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</div>


<!-- MODAL EDITAR PRODUTO -->
<div class="modal fade" id="modalEditarProduto" tabindex="-1" role="dialog" aria-labelledby="editProduto"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel"><b>Cadastrar Produto</b></h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="ui form">
                    <div class="field">
                        <label>Tipo</label>
                        <select class="ui fluid dropdown" [(ngModel)]="produto.tipoProduto" 
                            [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let m of tipoProdutoLista" class="item" [value]="m.cod">{{ m.descricao
                                }}</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>
                            <h2><b>Descricao</b></h2>
                        </label>
                        <input type="text" name="descricao"  [(ngModel)]="produto.descricao">
                    </div>
                   
                    <div class="field">
                        <label>
                            <h2><b>Valor</b></h2>
                        </label>
                        <input type="number" name="preco" [(ngModel)]="produto.preco">
                    </div>
                    
                </form>
            </div>
            <div class="modal-footer">
                <button class="ui primary button" (click)="putProduto()">
                    Salvar
                </button>
                <button class="ui button" data-dismiss="modal">
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</div>


