import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from 'src/app/models/login';
import { Parametros } from 'src/app/models/parametros';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  login = new Login();
  parametros = new Parametros();
 

  constructor(private provider: ApiServiceService, private router: Router) { }

  ngOnInit(): void {
  this.parametros.loading = false;
  }

  

  getLogin() {
    
    this.parametros.loading = true;
    return new Promise(resolve => {
      const dados = {
        requisicao: 'getLogin',
        login: this.login.login,
        senha: this.login.senha
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiCliente.php')
        .then((data) => {
          if (data['success']) {
            sessionStorage.setItem("IsLogged", "TRUE");
            sessionStorage.setItem("user", data['result'].login);
            sessionStorage.setItem("nivel_acesso", data['result'].nivel_acesso);
            sessionStorage.setItem("cod_empresa", data['result'].cod_empresa);
            sessionStorage.setItem("cod_cliente", data['result'].cod_cliente);
            this.parametros.loading = false;
            if(data['result'].nivel_acesso == 2){
              this.router.navigate([('/produto')]);
            }else if(data['result'].nivel_acesso == 1){
              this.router.navigate([('/usuario-produto')]);
            }else{
              Swal.fire({
                title: 'Ops!',
                text: 'Não foi possível identificar o nível de acesso do Usuário!',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            }
            
          } else {
            this.parametros.loading = false;
            Swal.fire({
              title: 'Ops!',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK'
            });
            
          }
        })
        .catch()
    })
  }

}
