<div>
    <input type="text" placeholder="Filtrar por nome" [(ngModel)]="filterText" (input)="applyFilter()">
  </div>
  
  <table class="ui celled blue table">
    <thead>
      <tr>
        <th><h4><b>Nome</b></h4></th>
        <th><h4><b>Email</b></h4></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of filteredUsers">
        <td>{{ user.name }}</td>
        <td>{{ user.email }}</td>
      </tr>
    </tbody>
  </table>
