import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Parametros } from 'src/app/models/parametros';
import { Produto } from 'src/app/models/produto';
import { ApiServiceService } from 'src/app/services/api-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.css']
})
export class ProdutoComponent implements OnInit {
  public produto = new Produto();
  public parametros = new Parametros();

  public produtoLista = [];
  public tipoProdutoLista = [];

  constructor(private provider: ApiServiceService, private router: Router) { }

  ngOnInit(): void {
    this.getTipoProduto();
    this.getProduto();
    this.parametros.loading = true;
    if(!sessionStorage.getItem("user")){
      this.router.navigate([('/login')]);
    }
  }
  
  limpaModal(){
    this.produto = new Produto();
  }

  preencheModal(cod,descricao, preco, tipo_produto){
    this.produto.cod  = cod;
    this.produto.descricao = descricao;
    this.produto.preco = preco;
    this.produto.tipoProduto = tipo_produto;
    //this.parametros.edita = 1;  
    //console.log(this.produto.cod_tipo_produto, this.produto.tipoProduto);
  }

  getTipoProduto() {
    this.tipoProdutoLista = [];
    return new Promise(resolve => {
      const dados = {
        requisicao: 'getTipoProduto',
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiProduto.php')
        .then((data) => {
          if (data['success']) {
            //console.log(data);
            for(let dado of data['result']){
              this.tipoProdutoLista.push(dado);
            }
          } else { 
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }
        })
        .catch()
    })
  }

  getProduto() {
    this.parametros.loading = true;
    this.produtoLista = [];
    return new Promise(resolve => {
      const dados = {
        requisicao: 'getProduto',
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiProduto.php')
        .then((data) => {
          if (data['success']) {
            this.parametros.loading = false;
            //console.log(data);
            for(let dado of data['result']){
              this.produtoLista.push(dado);
            }
          } else { 
            this.parametros.loading = false;
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }
        })
        .catch()
    })
  }

  postProduto(){
    return new Promise(resolve => {
      const dados = {
        requisicao: 'postProduto',
        descricao: this.produto.descricao,
        preco: this.produto.preco,
        tipo_produto: this.produto.tipoProduto
        //user: sessionStorage.getItem("user"),
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiProduto.php')
        .then((data) => {
          if (data['success']) {
            Swal.fire({
              title: 'PRODUTO',
              text: 'Cadastrado com Sucesso!',
              icon: 'success',
              confirmButtonText: 'Ok!',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          } else {
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }
        })
        .catch()
    })
  }

  decideDeleta(cod,produto){
    Swal.fire({
      title: 'ATENÇÃO',
      text: 'Deseja mesmo Excluir o produto '+produto+'?',
      icon: 'warning',
      confirmButtonText: 'SIM',
      showDenyButton: true,
      denyButtonText: 'NÃO'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteProduto(cod);
      }
    });
  }

  deleteProduto(cod){
    return new Promise(resolve => {
      const dados = {
        requisicao: 'deleteProduto',
        cod_produto: cod
        //user: sessionStorage.getItem("user"),
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiProduto.php')
        .then((data) => {
          if (data['success']) {
            Swal.fire({
              title: 'Produto',
              text: 'Excluido com Sucesso!',
              icon: 'success',
              confirmButtonText: 'Ok!',
            }).then((result)=>{
              window.location.reload();
            })
            
          } else {
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'Ok!',
            });
          }
        })
        .catch()
    })
  }

  putProduto(){
    return new Promise(resolve => {
      const dados = {
        requisicao: 'putProduto',
        descricao: this.produto.descricao,
        preco: this.produto.preco,
        tipo_produto: this.produto.tipoProduto,
        cod: this.produto.cod
        //user: sessionStorage.getItem("user"),
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      //console.log(dados);
      this.provider.api(dados, 'apiProduto.php')
        .then((data) => {
          if (data['success']) {
            Swal.fire({
              title: 'PRODUTO',
              text: 'ALTERADO COM SUCESSO!',
              icon: 'success',
              confirmButtonText: 'Ok!',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
            window.location.reload();
          } else {
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }
        })
        .catch()
    })
  }

}
