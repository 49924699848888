<div class="ui placeholder segment">
  <div class="ui two column stackable center aligned grid">
    <div class="middle aligned row">
      <div class="column">
        <img class="ui centered small  image" src="\assets\images\RS.jpg">
        <br>
        <div class="field">
          <form class="ui form">
            <div class="field">
              <label>Usuário ou nº CRO</label>
              <input type="text" [(ngModel)]="this.login.login" name="login">
            </div>
            <div class="field">
              <label>Senha</label>
              <input type="password" [(ngModel)]="this.login.senha" name="senha">
            </div>
            <div class="field">
              <label>Esqueceu a Senha?</label>
              <div>
                <a href="/alterar-senha">Redefinir Senha</a>
              </div>
            </div>

            <div class="ui blue huge submit fluid button" (click)="getLogin()">
              <div *ngIf="!this.parametros.loading">Acessar </div>
              <div *ngIf="this.parametros.loading" class="ui active inline loader"></div>
            </div>
          </form>
        </div>
      </div>
      <div class="ui vertical divider">Ou</div>
      <div class="column">
        <div class="ui icon header">
          <i class="user plus circle icon"></i>
          Novo aqui?
        </div>
        <div>
          <a href="/cadastro-login">Cadastre-se</a>
        </div>
      </div>
    </div>
  </div>
</div>