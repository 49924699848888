import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cliente } from 'src/app/models/cliente';
import { Parametros } from 'src/app/models/parametros';
import { ApiServiceService } from 'src/app/services/api-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cadastro-login',
  templateUrl: './cadastro-login.component.html',
  styleUrls: ['./cadastro-login.component.css']
})
export class CadastroLoginComponent implements OnInit {
  cliente = new Cliente();
  parametros = new Parametros();
  constructor(private provider: ApiServiceService, private router: Router) { }

  ngOnInit(): void {
   
  }


  postCliente(){
    this.parametros.loading = true;
    return new Promise(resolve => {
      const dados = {
        requisicao: 'postCliente',
        nome: this.cliente.nome,
        cro: this.cliente.cro,
        senha: this.cliente.senha,
        //user: sessionStorage.getItem("user"),
        //cod_empresa: sessionStorage.getItem("cod_empresa")
      };
      console.log(dados);
      this.provider.api(dados, 'apiCliente.php')
        .then((data) => {
          if (data['success']) {
            this.parametros.loading = false;
            Swal.fire({
              title: 'USUÁRIO',
              text: 'CADASTRADO COM SUCESSO!',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigateByUrl('/login');
              }
            });
            
          } else {
            this.parametros.loading = false;
            Swal.fire({
              title: 'Erro',
              text: data['erro'],
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }
        })
        .catch()
    })
  }

}
