 <!-- Load an icon library to show a hamburger menu (bars) on small screens -->
 

 <div class="topnav" id="Master" *ngIf="this.parametros.master">
   <a class="comecoLinha" routerLink="/produto" >Produtos</a>
   <a class="comecoLinha" routerLink="/receber">Clientes</a>
   <a class="comecoLinha" routerLink="/usuarios">Lista Usuários</a>
   <a class="fimLinha" (click)="sair()" slot=end>Sair</a>
   
 </div> 
 <div class="topnav" id="Usuario" *ngIf="!this.parametros.master">
  <a class="comecoLinha" routerLink="/usuario-produto" >Catálogo</a>
  <a class="comecoLinha" routerLink="/usuario-historico">Histórico</a>
  <a class="fimLinha" (click)="sair()">Sair</a>
  <a href="javascript:void(0);" class="icon" onclick="myFunction()">
    <i class="fa fa-bars"></i>
  </a>
</div> 