import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Parametros } from 'src/app/models/parametros';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  parametros = new Parametros();
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.validaAcesso();
  }

  validaAcesso(){
    if(sessionStorage.getItem("nivel_acesso") == '2'){
      this.parametros.master = true;
    }else{
      this.parametros.master = false;
    }
  }

  sair(){
    sessionStorage.removeItem("isLogged");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("nivel_acesso");
    sessionStorage.removeItem("cod_empresa");
    sessionStorage.removeItem("cod_cliente");
    this.router.navigate([('/login')]);
  }

}
